import React, { Component,useState,useEffect } from 'react';
import axios from "axios"
export default function Header(){
    const pathname = window.location.pathname
    const [show, setShow] = useState(false);
    const [profile, setProfile] = useState({access_token:"",user:{email:""}});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const showmenu =()=>{
      setShow(!show)
    }
    const onLogOut = async () => {
      // const config = {
      //   headers: { Authorization: `Bearer ${profile.access_token}` }
      // };
      
      // const bodyParameters = {
      //   key: "value"
      // };
      // try {
      //     const response:any = await axios.post(
      //     `https://admin.penguinasset.com/api/auth/logout`,bodyParameters,config
      //     );
      //     sessionStorage.removeItem("profile");
      //     window.location.href="/"
      // } catch (err:any) {
      //     setError(err.message);
      
      // } finally {
      //     setLoading(false);
      // }
      sessionStorage.removeItem("profile");
      window.location.href="/"
  };
    useEffect(() => {
      if(sessionStorage.getItem('profile')!== null){
        let login:any =JSON.parse(sessionStorage.getItem('profile')!);
        setProfile(login)
        console.log(login)
      }
   },[])
    return (
        <div className="container-fuild container-navbar">
        <nav className={`navbar navbar-expand-xl navbar-light bg-white ${(show)? "navbar-show":""}`}>
        <a className="navbar-brand" href="/"><img src='../../logo-penguinasset.png' className='logo-penguinasset'/></a>
        <button className="navbar-toggler" onClick={()=>showmenu()}  type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="menu-line"></span>
          <span className="menu-line"></span>
          <span className="menu-line"></span>
        </button>

        <div className={`collapse navbar-collapse  ${(show)? "show":""}`} id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
         
            <li className="nav-item">
              <a className={`nav-link ${(pathname==="/"||pathname==="/home")? "a-active":""}`} href="/">หน้าแรก</a>
            </li>
            <li className="nav-item menu-asset">
              <a className={`nav-link ${(pathname==="/assetsstore"||pathname==="/asset")? "a-active":""}`} href="/assetsstore">สินทรัพย์</a>
              <ul className='submenu'>
                <li><a href="/type/บ้านเดี่ยว">บ้านเดี่ยว</a></li>
                <li><a href="/type/ทาวน์เฮ้าส์">ทาวน์เฮ้าส์</a></li>
                <li><a href="/type/คอนโด">คอนโดมิเนียม</a></li>
                <li><a href="/type/อาคารพาณิชย์">อาคารพาณิชย์</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${(pathname==="/consignment")? "a-active":""}`} href="/consignment">ฝากขาย</a>
            </li>
            {/* <li className="nav-item">
              <a className={`nav-link ${(pathname==="/ourworks")? "a-active":""}`} href="/ourworks">ผลงาน</a>
            </li> */}
            <li className="nav-item">
              <a className={`nav-link ${(pathname==="/aboutus")? "a-active":""}`} href="/aboutus">รู้จักเพนกวินแอสเซท</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${(pathname==="/blogs")? "a-active":""}`} href="/blogs">บทความ</a>
            </li>
            <li>
            <a className={`nav-link ${(pathname==="/Calculate_house_interest")? "a-active":""}`} href="/Calculate_house_interest">คำนวนดอกเบี๊ย</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${(pathname==="/contactus")? "a-active":""}`} href="/contactus">ติดต่อเรา</a>
            </li>
          </ul>
        </div>
        <div className='account'>
          {
            (profile.user.email!=="")?
              <div className='profile text-end'>
                <img src='../../icon/user.png'/>
                <a href="/favorites" className='mr-0 d-block'>{profile?.user.email}</a>
                <a onClick={()=>onLogOut()} className='logout cursor-pointer d-block' >Logout</a>
              </div>
            :
            <>
            <a href="/login">Login</a><a href="/signin" className='btn-penguin'>Sign up</a>
            </>
            
          }
          
        </div>
        <div className={`h-followus ${(profile.user.email!=="")?"mt-3":""}`}>
         <div>Follow Us</div> 
          <ul>
              
              <li><a href="https://www.facebook.com/PenguinAsset/" target="_blank"><img src='../../icon/fb-r.png'/></a></li>
              <li><a href="https://lin.ee/oK9IApV" target="_blank"><img src='../../icon/line-r.png'/></a></li>
              <li><a href="https://www.tiktok.com/@penguinasset.official?_t=8Ve0YkVJvbG&_r=1"><img src='../../icon/tiktok-r.png'/></a></li>
              <li><a href="https://instagram.com/penguinasset.official?igshid=YmMyMTA2M2Y="><img src='../../icon/ig-r.png'/></a></li>
          </ul>
        </div>
      </nav>    
      </div>
    );
  }
